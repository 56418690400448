import React from "react";
import "./privacypolicy.css";
const CookiesPolicy = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div class="page">
        <div>
          <h2>Cookies Policy Update</h2>
          <p>
            <strong>Effective Date:</strong> February 13, 2024
          </p>
          <p>
            This Cookies Policy outlines the use of cookies by Nexgen AI,
            operated by Bsdr Vision Technologies ("Company", "We", "Us", or
            "Our"). Understanding the role of cookies and our use of them will
            help you make informed decisions about your interaction with our
            Website. This policy is aligned with our commitment to transparency
            and compliance with Google's requirements regarding cookie usage.
          </p>

          <h3>Definitions:</h3>
          <ul>
            <li>
              <strong>Company:</strong> refers to Bsdr Vision Technologies,
              located at D.no 13/349-j-25, Ushakiran High School Pyramid Nagar
              Vempalli, Cuddapah Cuddapah Ap 516329.
            </li>
            <li>
              <strong>Cookies:</strong> are small data files placed on your
              device by websites, containing browsing history and user
              preferences.
            </li>
            <li>
              <strong>Website:</strong> is Nexgen AI, accessible via{" "}
              <a href="https://nexgenai.team">https://nexgenai.team</a>.
            </li>
            <li>
              <strong>You:</strong> denotes the Website user, whether an
              individual or a legal entity.
            </li>
          </ul>

          <h3>Use of Cookies:</h3>
          <p>
            Cookies on our Website can be categorized as "Session" or
            "Persistent":
          </p>
          <ul>
            <li>
              <strong>Session Cookies:</strong> are temporary and deleted when
              you close your browser.
            </li>
            <li>
              <strong>Persistent Cookies:</strong> remain on your device until
              they expire or are deleted manually.
            </li>
          </ul>
          <p>
            We utilize these cookies for essential functions and to enhance user
            experience:
          </p>
          <ul>
            <li>
              <strong>Necessary/Essential Cookies:</strong> These are crucial
              for accessing services on our Website, enabling features like
              authentication and account security. They do not require consent
              and are automatically placed on your device.
            </li>
            <li>
              <strong>Functionality Cookies:</strong> These cookies remember
              your preferences (like language settings) to provide a
              personalized experience.
            </li>
          </ul>

          <h3>Managing Cookies:</h3>
          <p>
            You have control over the use of cookies on our Website. Adjust your
            browser settings to disable cookies if you prefer. However, this
            might affect your user experience and functionality availability on
            the Website. To manage cookies, follow guidance provided by your
            browser's support documentation:
          </p>
          <p>
            For the Chrome web browser, please visit this page from Google:{" "}
            <a href="https://support.google.com/accounts/answer/32050">
              https://support.google.com/accounts/answer/32050
            </a>
          </p>
          <p>
            For the Internet Explorer web browser, please visit this page from
            Microsoft:{" "}
            <a href="http://support.microsoft.com/kb/278835">
              http://support.microsoft.com/kb/278835
            </a>
          </p>
          <p>
            For the Firefox web browser, please visit this page from Mozilla:{" "}
            <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
              https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
            </a>
          </p>
          <p>
            For the Safari web browser, please visit this page from Apple:{" "}
            <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
            </a>
          </p>
          <p>
            For browsers not listed here, please consult the respective
            documentation for cookie management instructions.
          </p>

          <h3>Further Information on Cookies:</h3>
          <p>
            To understand more about cookies and their impact, you might want to
            research online resources that explain how cookies function and
            their common uses.
          </p>

          <h3>Contact Us:</h3>
          <p>
            For any inquiries regarding this Cookies Policy, please reach out to
            us via email at
            <a href="mailto:support@nexgenai.team">email</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
