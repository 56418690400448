import React from "react";
import Styled from "styled-components";
const Heading = Styled.span`
margin:0px;
color:${(props) => props.ftColor};
font-size:${(props) => (props.size ? props.size : "1.5rem")};
font-weight:${(props) => (props.weight ? props.weight : "normal")};
`;
const Text = ({ ftColor, ftSize, val, ftWeight, callback = null }) => {
  //   console.log(ftSize);
  return (
    <Heading
      onClick={callback}
      ftColor={ftColor}
      size={ftSize}
      weight={ftWeight}
    >
      {val}
    </Heading>
  );
};

export default Text;
