import React, { useState, useContext, useEffect } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import Loader from "../General/Loader";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../redux/leadsReducer";

const Container = Styled.div``;

const Wrapper = Styled.div``;

const DetailsContainer = Styled.div`
flex:1;
width:100%;
padding-left:20px;
padding-right:20px;
box-sizing:border-box;
margin-top:0px;


`;
const LoaderContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  
`;
const Row = Styled.div`
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  
`;

const RowFilled = Styled.div`
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${(props) => props.colorTheme.backPri};
`;

const Key = Styled.div`
  padding: 12px;
  font-weight: bold;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 1;
`;

const Value = Styled.div`
  padding: 12px;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 1;
`;
const ValueNum = Styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding: 12px;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 0.3;
`;

const SendContainer = Styled.div`

padding:20px 20px;
padding-bottom:0px;
display:flex;
position:sticky;
top:0;
align-items:center;
justify-content:center;
border-bottom-right-radius:10px;
border-bottom-left-radius:10px;
flex-direction:column;
background-color:${(props) => props.colorTheme.backSec};

`;

const Center = Styled.div`
width:60%;
flex:1;
display:flex;
align-items:center;
justify-content:center;`;

const InputContainer = Styled.div`
flex:9;
height:45px;
display:flex;
align-items:center;
jusify-content:center;
border:none;
border-radius:50px;
padding:2px 20px;
background-color:${(props) => props.colorTheme.backPri};
`;
const Input = Styled.input`
font-size: 18px;
font-size: 18px;
flex: 1;
width:80%;
border:none;
height: 30px;
outline:none;
&:focus{
 font-size: 18px;
font-size: 18px;
flex: 1;
border:none;
height: 35px;
outline:none;
};
background-color:${(props) => props.colorTheme.backPri};
color:${(props) => props.colorTheme.fontPri};
`;

const Home = () => {
  const { colorTheme } = useContext(ContextProvider);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.leads.currentData);
  const [filteredUserData, setFilteredUserData] = useState(userData);
  const user = useSelector((state) => state.user.currentUser);
  const handleSearchChange = (e) => {
    const newSearchQuery = e.target.value;
    setSearchQuery(newSearchQuery);
    const filterVal = (user) => {
      return (
        user.lead?.json_meta.employee_meta.employee_linkedin
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.lead?.json_meta.employee_meta.employee_name
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.lead?.json_meta.employee_meta.title
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.lead?.json_meta.company_meta.company_name
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.lead?.json_meta.company_meta.company_country
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.lead?.json_meta.company_meta.industry
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase()) ||
        user.status
          .toString()
          .toLowerCase()
          .includes(newSearchQuery.toLowerCase())
      );
    };
    const filteredData = userData.filter(
      (user) => {
        return filterVal(user);
      }
      // Object.values(user).some((value) =>
      //   value.toString().toLowerCase().includes(newSearchQuery.toLowerCase())
      // )
    );

    setFilteredUserData(filteredData);
  };
  useEffect(() => {
    const getStatus = async () => {
      setLoading(true);
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/leads/getLeadsForUser`,
          { headers: { Authorization: `Bearer ${user.accessToken}` } }
        );
        console.log(res.data.leads);
        dispatch(setData(res.data.leads));
        dispatch(setFilteredUserData(res.data.leads));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getStatus();
  }, []);
  // userData.map((item) => console.log(item.lead?.json_meta?.employee_meta));
  return (
    <Container>
      <Wrapper>
        <SendContainer colorTheme={colorTheme}>
          <Center colorTheme={colorTheme}>
            <div style={{ padding: "20px" }}>
              <span style={{ color: colorTheme.fontPri, fontWeight: "bold" }}>
                Total Leads : {userData ? userData.length : 0}
              </span>
            </div>
            <InputContainer
              colorTheme={colorTheme}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                }
              }}
            >
              <Input
                colorTheme={colorTheme}
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="NavbarInput"
              />
            </InputContainer>
          </Center>
          <div style={{ marginTop: "20px", width: "100%" }}>
            <RowFilled colorTheme={colorTheme}>
              <ValueNum colorTheme={colorTheme}>S.No</ValueNum>
              <Key colorTheme={colorTheme}>Name</Key>

              <Value colorTheme={colorTheme}>Title</Value>
              <Value colorTheme={colorTheme}>Company</Value>
              <Value colorTheme={colorTheme}>Country</Value>

              <Value colorTheme={colorTheme}>Industry</Value>
              <Value colorTheme={colorTheme}>Status</Value>
            </RowFilled>
          </div>
        </SendContainer>
        <DetailsContainer>
          {loading && (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          )}
          {/* <RowFilled colorTheme={colorTheme}>
            <Key colorTheme={colorTheme}>Name</Key>

            <Value colorTheme={colorTheme}>Title</Value>
            <Value colorTheme={colorTheme}>Company</Value>
            <Value colorTheme={colorTheme}>Country</Value>

            <Value colorTheme={colorTheme}>Industry</Value>
            <Value colorTheme={colorTheme}>Status</Value>
          </RowFilled> */}
          {filteredUserData &&
            filteredUserData?.map((user, index) =>
              index % 2 === 0 ? (
                <Row colorTheme={colorTheme}>
                  <ValueNum colorTheme={colorTheme}>{index + 1}</ValueNum>
                  <Value colorTheme={colorTheme}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={user?.lead?.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Key
                        colorTheme={colorTheme}
                        style={{ textDecoration: "underline" }}
                      >
                        {user?.lead?.name}
                      </Key>
                    </a>
                  </Value>
                  <Value colorTheme={colorTheme}>{user?.lead?.title}</Value>
                  <Value colorTheme={colorTheme}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={"https://" + user?.lead?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Value
                        colorTheme={colorTheme}
                        style={{ textDecoration: "underline" }}
                      >
                        {user?.lead?.company}
                      </Value>
                    </a>
                  </Value>
                  <Value colorTheme={colorTheme}>{user?.lead?.country}</Value>
                  <Value colorTheme={colorTheme}>{user.lead?.industry}</Value>
                  <Value colorTheme={colorTheme}>{user.status}</Value>
                </Row>
              ) : (
                <RowFilled colorTheme={colorTheme}>
                  <ValueNum colorTheme={colorTheme}>{index + 1}</ValueNum>
                  <Value colorTheme={colorTheme}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={user?.lead?.linkedin}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Key
                        colorTheme={colorTheme}
                        style={{ textDecoration: "underline" }}
                      >
                        {user?.lead?.name}
                      </Key>
                    </a>
                  </Value>
                  <Value colorTheme={colorTheme}>{user?.lead?.title}</Value>
                  <Value colorTheme={colorTheme}>
                    <a
                      style={{ textDecoration: "none" }}
                      href={"https://" + user?.lead?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Value
                        colorTheme={colorTheme}
                        style={{ textDecoration: "underline" }}
                      >
                        {user?.lead?.company}
                      </Value>
                    </a>
                  </Value>
                  <Value colorTheme={colorTheme}>{user?.lead?.country}</Value>
                  <Value colorTheme={colorTheme}>{user.lead?.industry}</Value>
                  <Value colorTheme={colorTheme}>{user.status}</Value>
                </RowFilled>
              )
            )}
        </DetailsContainer>
      </Wrapper>
    </Container>
  );
};
export default Home;
