import React from "react";
import Styled from "styled-components";
import FeatureBox from "./FeatureBox";
import { Mobile } from "../../responsive";

const Container = Styled.div`
display : flex;
align-items:center;
justify-content:space-between;
${Mobile({
  flexDirection: "column",
})}`;

const data = [
  {
    img: "static/img1.jpg",
    title: "Sophia, The Sales Expert",
    subtitle: "Outbound Sales Expert: Master of Follow-Ups and Meetings",
    status: "Access",
    description: [
      "Has access to an extensive database of over 500 million contacts, along with insightful buyer intent data.",
      "Skillfully designs and dispatches highly effective cold email sequences automatically to the appropriate targets.",
      "Efficiently schedules meetings directly into SDRs' calendars without conflicts.",
    ],
  },
  {
    img: "static/img2.jpg",
    title: "Ethan, The Content Creator",
    subtitle: "Enhancing Your Company's Public Image",
    status: "Coming Soon",
    description: [
      "Capable of crafting and disseminating highly engaging content across all social media platforms automatically.",
      "Continuously evolves, keeping pace with company updates and effectively translating them into sales opportunities.",
      "Automatically responds to user comments and interacts with them, fostering a dynamic online presence.",
    ],
  },
  {
    img: "static/img3.jpg",
    title: "Nathan, The Graphic Designer",
    subtitle: "Rapid Design Excellence",
    status: "Coming Soon",
    description: [
      "Expert in creating visually appealing posts and editing videos, plus generating highly engaging short-form content videos.",
      "Capable of instantly creating up to 100 diverse and compelling designs each day.",
      "Utilizes data-driven insights, learns from engagement analytics to evolve, enhancing effectiveness by measurable degrees.",
    ],
  },
];

const Features = () => {
  return (
    <Container>
      {data.map((item) => (
        <FeatureBox
          img={item.img}
          title={item.title}
          subtitle={item.subtitle}
          description={item.description}
          status={item.status}
        />
      ))}
    </Container>
  );
};

export default Features;
