import React from "react";
import Styled from "styled-components";

const Container = Styled.button`
padding: 10px 25px;
font-size: 18px;
color: white;
border:none;
background-image: linear-gradient(to right, #a55eea 0%, #5f27cd 51%, #6f27cd 100%);
border-radius: 50px; 
text-align: center;
text-decoration: none;
display: inline-block;
cursor: pointer;
outline: none;
transition: all 0.3s ease;  
font-family: 'Lato', sans-serif;
  &:hover{
  transform:scale(1.1);
} ; 
`;

const Button = ({ text, text2 }) => {
  return (
    <Container>
      {text}
      <br />
      {text2}
    </Container>
  );
};

export default Button;
