import { createSlice } from "@reduxjs/toolkit";

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    currentData: null,
  },
  reducers: {
    setData: (state, action) => {
      state.currentData = action.payload;
    },
    logoutChat: (state) => {
      state.currentData = null;
    },
  },
});

export const { setData, logoutChat } = analyticsSlice.actions;

export default analyticsSlice.reducer;
