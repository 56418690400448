import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./redux/store";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { NylasProvider } from "@nylas/nylas-react";
let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <NylasProvider serverBaseUrl={process.env.REACT_APP_API_URL}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </NylasProvider>
    </PersistGate>
  </Provider>
);
