import { createSlice } from "@reduxjs/toolkit";

const chatsSlice = createSlice({
  name: "chats",
  initialState: {
    currentData: [],
  },
  reducers: {
    setData: (state, action) => {
      state.currentData = action.payload;
    },
  },
  logoutChats2: (state) => {
    state.currentData = [];
  },
});

export const { setData, logoutChats2 } = chatsSlice.actions;

export default chatsSlice.reducer;
