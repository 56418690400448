import { css } from "styled-components";
export const Mobile = (props) => {
  return css`
    @media only screen and (max-width: 600px) {
      ${props}
    }
  `;
};
export const Tablet = (props) => {
  return css`
    @media only screen and (max-width: 1000px) {
      ${props}
    }
  `;
};
export const Min = (props) => {
  return css`
    @media only screen and (max-width: 459px) {
      ${props}
    }
  `;
};
