import React, { useState } from "react";
import Styled from "styled-components";
import Heading from "./Heading";
import Button from "./Button";
import { Mobile } from "../../responsive";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
const Container = Styled.div`
width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Mobile({ flexDirection: "column" })};
`;

const Left = Styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Mobile({ width: "100%" })};
`;

const Right = Styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20rem;
  ${Mobile({
    width: "100%",
    display: (props) => (props.isOpen ? "flex" : "none"),
    flexDirection: "column",
    justifyContent: "flex-start",
  })}
`;

const MenuToggle = Styled.div`
  display: none;
  ${Mobile({ display: "block" })};
  background-color: transparent;`;

const Links = Styled.span`
  font-size: 1.3em;
  font-weight: 600;
  cursor: pointer;
  color: grey;
  ${Mobile({
    width: "100%",
    margin: "5px",
  })}
`;
const Cont = Styled.div`

flex:1;
display:flex;
align-items:center;
justify-content:space-between;
`;
const Contt = Styled.div`

flex:1;
display:flex;
align-items:center;
justify-content:flex-end;
`;
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Left>
        <Cont>
          <Heading />
        </Cont>
        <Contt>
          <MenuToggle onClick={() => setIsOpen(!isOpen)}>
            <MenuRoundedIcon
              style={{
                cursor: "pointer",
                height: "40px",
                width: "40px",
                color: "white",
              }}
            />
          </MenuToggle>
        </Contt>
      </Left>
      <Right isOpen={isOpen}>
        {/* <Links>Product</Links>
        <Links>Features</Links> */}
        <Links>
          {/* <Button text={"Sign Up"} /> */}
          <a
            href="https://calendly.com/dheemanthreddy/meeting"
            target={"blank"}
          >
            <Button text={"Schedule A Call"} />
          </a>
        </Links>
      </Right>
    </Container>
  );
};

export default Navbar;
