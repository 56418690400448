import React, { useState, useContext } from "react";
import { ContextProvider } from "../App";
import { Mobile, Tablet } from "../responsive";
import { loginStart, loginSuccess, loginFailure } from "../redux/userReducer";
import Text from "../components/General/Text";
import Styled from "styled-components";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/General/Loader";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/emailAccess";
const Container = Styled.div`
font-family:QuickSand,Sans-Serif;
height:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;
`;

const Wrapper = Styled.div`
width:35%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:10px;
background-color:${(props) => props.bgColor};
${Mobile({
  width: "80%",
})};
${Tablet({
  width: "70%",
})};

`;

const HeadingContainer = Styled.div`
padding:20px 30px;
display:flex;
align-items:center;
justify-content:center;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;

const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 20px;
`;

const TitleContainer = Styled.div`
padding:10px 10px 0px 10px;
display:flex;
align-items:center;
justify-content:center;
color:${(props) => props.colorTheme.fontPri};
`;
const Title = Styled.h1`
margin: 0px auto 0px auto;
cursor:pointer;
font-size:32px;;`;
const Span = Styled.span`
font-size:32px;
color:#0081B4;`;
const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;
const AdditionalContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;
const Text2 = Styled.span`
cursor:pointer;
margin:0px;
text-decoration:underline;
color:${(props) => props.ftColor};
font-size:${(props) => (props.size ? props.size : "1.1rem")};
font-weight:${(props) => (props.weight ? props.weight : "normal")};
`;
const Text3 = Styled.span`
cursor:pointer;
margin:0px;
color:${(props) => props.ftColor};
font-size:${(props) => (props.size ? props.size : "1.1rem")};
font-weight:${(props) => (props.weight ? props.weight : "normal")};
`;
const Login = () => {
  const { colorTheme, theme, setNotification } = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [password, setPassword] = useState("");
  const { backPri, backSec, fontPri, fontSec } = colorTheme;

  const dispatch = useDispatch();
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(loginStart());
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/login`,
        {
          email: email,
          password: password,
        }
      );

      // console.log(response.data);
      dispatch(loginSuccess(response.data));
      if (response.data.hasAuthorizedMail) {
        // console.log("hasAuthorizedMail", response.data.userId);
        dispatch(setUser(response.data.userId));
      }
      setNotification("success", "Logged In Successfully");
      navigate("/");
    } catch (error) {
      setNotification("failure", "Invalid Credentials");
      console.log(error);
      dispatch(loginFailure());
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
      <Wrapper bgColor={backSec}>
        <TitleContainer colorTheme={colorTheme}>
          <Title>
            Nexgen
            <Span>AI</Span>
          </Title>
        </TitleContainer>

        <HeadingContainer>
          <Text
            ftColor={fontPri}
            val={"Login"}
            ftSize={"32px"}
            ftWeight={"Bolder"}
          ></Text>
        </HeadingContainer>
        <ContentContainer>
          <FormContainer>
            <Form>
              <InputContainer
                label="email"
                placeholder="abc@gmail.com"
                type="email"
                input={email}
                setInput={setEmail}
              />

              <InputContainer
                label="password"
                placeholder="password"
                type="password"
                input={password}
                setInput={setPassword}
              ></InputContainer>
            </Form>
          </FormContainer>
          <ButtonContainer>
            <Button
              text={loading ? <Loader /> : "Login"}
              compose={handleLogin}
            ></Button>
          </ButtonContainer>
          <AdditionalContainer>
            <Text3 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
              Don't have an account?
            </Text3>
            <Text2
              ftColor={fontSec}
              ftSize={"20px"}
              ftWeight={"bolder"}
              onClick={() => navigate("/register")}
            >
              {"Register"}
            </Text2>
          </AdditionalContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Login;
