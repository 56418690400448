import React, { useState, useEffect, useContext } from "react";
import Styled from "styled-components";
import Button from "../General/Button";
import { ContextProvider } from "../../App";
import { useNylas } from "@nylas/nylas-react";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import Loader from "../General/Loader";
import { setData } from "../../redux/inboxReducer";

const LoaderContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  top:0px;
  position:absolute;
`;
const PageContainer = Styled.div`
  display: flex;
  
`;

const LeftPanel = Styled.div`
  width: 40%;
  border-right:solid 1px;
  
  border-color:${(props) => props.colorTheme.backPri};
  height:calc(100vh - 60px);
  overflow: scroll;
  &::-webkit-scrollbar{
    display:none;
  };
  
`;

const RightPanel = Styled.div`
  /* margin-top:40px; */
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height:calc(100vh - 60px);
  overflow: scroll;
  &::-webkit-scrollbar{
    display:none;
  };
`;

const EmailList = Styled.div`
  padding: 20px;
`;

const EmailListItem = Styled.div`
  padding: 10px;
  border-radius:10px;
  margin-bottom:10px;
  background-color: ${(props) => props.colorTheme.backPri};
  color:${(props) => props.colorTheme.fontSec};
  cursor: pointer;
  &:hover {
   background-color: ${(props) => props.colorTheme.backPri};
  };

`;

const MailView = Styled.div`
  width: 80%;
  padding: 20px;
  color: ${(props) => props.colorTheme.fontPri};
`;

const TopContainer = Styled.div`
padding:20px;
padding-bottom:0px;
top:0;
display:flex;
align-items:center;
justify-content:flex-start;
flex-direction:column;
background-color: ${(props) => props.colorTheme.backSec};
padding-bottom:10px;
position:sticky;`;

const TopLeftContainer = Styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
flex:1;`;

const TopRightContainer = Styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
margin-top: 20px; 
flex:1;`;

const Heading = Styled.span`
font-size:25px;
font-weight:bold;
color:${(props) => props.colorTheme.fontPri};

`;
const LabelsContainer = Styled.div`
display:flex;
align-items:center;
justify-content:flex-start;
padding-top:20px;
`;

const EmailBody = Styled.div`
padding-top:20px;

height:80vh;
width:100%;
overflow:scroll;
&::-webkit-scrollbar {
  display: none;
};
`;

const SidebarsContainer = Styled.div`
cursor:pointer;
display:flex;
height:50px;
align-items:center;
justify-content:center;
border:${(props) => props.active && "solid 1px lightgrey"};
border-top-right-radius:${(props) => props.active && "5px"};
border-top-left-radius:${(props) => props.active && "5px"};
border-bottom: ${(props) => props.active && "none"};
flex:1;`;

const SearchBarContainer = Styled.div`
cursor:pointer;
flex:1;
height:50px;
display:flex;
align-items:center;
justify-content:center;
border:${(props) => !props.active && "solid 1px lightgrey"};
border-top-right-radius:${(props) => !props.active && "5px"};
border-top-left-radius:${(props) => !props.active && "5px"};
border-bottom: ${(props) => !props.active && "none"};
`;
const Span = Styled.span`
font-size:24px;
color:${(props) => props.colorTheme.fontPri};
font-weight:500;`;
const Preview = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
height:100%;`;
const MailPage = ({ disconnectUser }) => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [composeEmail, setComposeEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const nylas = useNylas();
  const user = useSelector((state) => state.user.currentUser);
  const [active, setActive] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  // const [emails, setEmails] = useState({});
  const dispatch = useDispatch();
  const emails = useSelector((state) => state.inbox.currentData);
  const handleEmailSelect = (email) => {
    setSelectedEmail(email);

    setComposeEmail(false);
  };
  const getSentEmails = async () => {
    setLoading(true);
    try {
      const inboxemails = await Axios.get(
        `${process.env.REACT_APP_API_URL}/api/nylas/getallemails`,
        { headers: { Authorization: `Bearer ${user.accessToken}` } }
      );
      dispatch(setData(inboxemails.data));
    } catch (error) {}
    setLoading(false);
  };
  useEffect(() => {
    getSentEmails();
  }, []);
  console.log(emails);

  return (
    <PageContainer>
      <LeftPanel colorTheme={colorTheme}>
        <TopContainer colorTheme={colorTheme}>
          {/* <button onClick={() => disconnectUser()}>Disconnect User</button> */}
          <TopLeftContainer>
            <Heading colorTheme={colorTheme}>Recent Mails</Heading>
            <Button
              text={loading ? <Loader /> : "Refresh"}
              compose={() => getSentEmails()}
            ></Button>
          </TopLeftContainer>
          <TopRightContainer>
            <SidebarsContainer active={active} onClick={() => setActive(true)}>
              <Span colorTheme={colorTheme}>Inbox</Span>
            </SidebarsContainer>
            <SearchBarContainer
              active={active}
              onClick={() => setActive(false)}
            >
              <Span colorTheme={colorTheme}>Sent</Span>
            </SearchBarContainer>
          </TopRightContainer>
        </TopContainer>

        {emails && (
          <EmailList>
            {active
              ? emails?.inbox?.map((email) => (
                  <EmailListItem
                    colorTheme={colorTheme}
                    key={email.id}
                    onClick={() => handleEmailSelect(email)}
                  >
                    <div>
                      <strong>From:</strong> {email.from[0].name}
                    </div>
                    <div>
                      <strong>Subject:</strong> {email.subject}
                    </div>
                  </EmailListItem>
                ))
              : emails?.sentbox?.map((email) => (
                  <EmailListItem
                    colorTheme={colorTheme}
                    key={email.id}
                    onClick={() => handleEmailSelect(email)}
                  >
                    <div>
                      <strong>To:</strong> {email.to[0].name}
                    </div>
                    <div>
                      <strong>Subject:</strong> {email.subject}
                    </div>
                  </EmailListItem>
                ))}
          </EmailList>
        )}
      </LeftPanel>
      <RightPanel colorTheme={colorTheme}>
        {selectedEmail ? (
          <MailView colorTheme={colorTheme}>
            <div>
              <strong>{selectedEmail.subject}</strong>
            </div>
            <LabelsContainer>
              {selectedEmail.labels.map((label) => (
                <div
                  style={{
                    backgroundColor: colorTheme.backPri,

                    color: colorTheme.fontSec,
                    padding: "5px",
                    borderRadius: "5px",
                    marginRight: "5px",
                  }}
                >
                  {label.name}
                </div>
              ))}
            </LabelsContainer>
            <div style={{ paddingTop: "20px" }}>
              <strong>From: </strong>
              {selectedEmail.from[0].name}
            </div>
            <EmailBody>
              <iframe
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  backgroundColor: "grey",
                }}
                title="myIframe"
                srcdoc={selectedEmail.body}
              ></iframe>
            </EmailBody>
          </MailView>
        ) : (
          <Preview style={{ color: colorTheme.fontPri, marginTop: "30px" }}>
            <img
              alt={""}
              style={{ height: "200px", width: "200px" }}
              src="/static/illustration-email.svg"
            />
            <Span colorTheme={colorTheme}>Select An Email To View</Span>
          </Preview>
        )}
      </RightPanel>
    </PageContainer>
  );
};

export default MailPage;
