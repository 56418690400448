import { createSlice } from "@reduxjs/toolkit";

const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState: {
    currentData: null,
  },
  reducers: {
    setData: (state, action) => {
      state.currentData = action.payload;
    },
    logoutKnowledge: (state) => {
      state.currentData = null;
    },
  },
});

export const { setData, logoutKnowledge } = knowledgeSlice.actions;

export default knowledgeSlice.reducer;
