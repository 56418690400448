import React, { useState } from "react";
import Styled from "styled-components";
import Input from "./Input";
import { Mobile } from "../../responsive";
const Container = Styled.div`

display: flex;
margin-top:10vh;
margin-bottom:10vh;
justify-content: center;
align-items: center;
text-align:center;
flex-direction:column;
${Mobile({ paddingTop: "2vh" })};
`;

const Span = Styled.h1`
padding: 2rem;
font-size: 3.9rem;
font-weight: 600;
cursor: pointer;
color:white;
`;
const SubSpan = Styled.span`
padding-top: 2rem ;
padding-bottom: 2rem ;
font-size: 1.8rem;
font-weight: 600;
cursor: pointer;
color:white;
`;
const Error = Styled.span`

font-size: 1.2rem;
margin-top:30px;
font-weight: 600;
cursor: pointer;

color:red;
`;

const Desc = () => {
  const [error, setError] = useState(false);
  return (
    <Container>
      <Span>
        Hire AI Digital Workers
        <br /> 10X Faster and Works 24/7
      </Span>
      <SubSpan>
        Easily Integrate a Talented Team Member in Just 10 Minutes !
      </SubSpan>
      <Input setError={setError} />
      <Error>{error && "Please Enter a Valid Email"}</Error>
    </Container>
  );
};

export default Desc;
