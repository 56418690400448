import { createSlice } from "@reduxjs/toolkit";

const leadsSlice = createSlice({
  name: "leads",
  initialState: {
    currentData: null,
  },
  reducers: {
    setData: (state, action) => {
      state.currentData = action.payload;
    },
    logoutLeads: (state) => {
      state.currentData = null;
    },
  },
});

export const { setData, logoutLeads } = leadsSlice.actions;

export default leadsSlice.reducer;
