import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import LineChart from "./SimpleLineChart";
import { ContextProvider } from "../../App";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../redux/analyticsReducer";
import Loader from "../General/Loader";
const Container = Styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items:center;
  justify-content:space-around;
  padding: 20px;
  
`;

const Widget = Styled.div`
  flex: 0 0 calc(25.33% - 20px);
  background-color: ${(props) => props.colorTheme.backPri};
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color:${(props) => props.colorTheme.fontPri};

  h2 {
    margin-bottom: 10px;
    font-size: 18px;
    color:${(props) => props.colorTheme.mainColor};
  }

  p {
    font-size: 24px;
    font-weight: bold;
  }
`;
const ChartContainer = Styled.div`
    width:100%;
    color:${(props) => props.colorTheme.fontPri};
`;
const LoaderContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  top:0px;
  /* position:absolute; */
`;

const Dashboard = () => {
  const dispatch = useDispatch();
  const analyticsData = useSelector((state) => state.analytics.currentData);
  // const [analyticsData, setAnalyticsData] = useState({
  //   totalMailsSent: 0,
  //   messagesRead: 0,
  //   messagesReplied: 0,
  //   messageLinkClicked: 0,
  //   followingup: 0,
  //   mailsLast7Days: [
  //     {
  //       day: "Friday",
  //       count: 5,
  //     },
  //     {
  //       day: "Thursday",
  //       count: 4,
  //     },
  //     {
  //       day: "Wednesday",
  //       count: 0,
  //     },
  //     {
  //       day: "Tuesday",
  //       count: 0,
  //     },
  //     {
  //       day: "Monday",
  //       count: 0,
  //     },
  //     {
  //       day: "Sunday",
  //       count: 0,
  //     },
  //     {
  //       day: "Saturday",
  //       count: 0,
  //     },
  //   ],
  // });
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const { colorTheme } = useContext(ContextProvider);
  useEffect(() => {
    const getStatus = async () => {
      setLoading(true);
      // console.log(user.email);
      try {
        const res = await Axios.post(
          `${process.env.REACT_APP_API_URL}/api/message/getstatus`,
          { email: user.email },
          { headers: { Authorization: `Bearer ${user.accessToken}` } }
        );

        dispatch(setData(res.data));
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    getStatus();
  }, []);

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {!analyticsData ? (
        <></>
      ) : (
        <>
          {/* <ChartContainer colorTheme={colorTheme}>
            <LineChart data={analyticsData?.mailsLast7Days} />
          </ChartContainer> */}

          <Widget colorTheme={colorTheme}>
            <h2>Monthly Target</h2>
            <p>800</p>
          </Widget>
          <Widget colorTheme={colorTheme}>
            <h2>Total Mails Sent</h2>
            <p>{analyticsData?.totalMailsSent}</p>
          </Widget>

          {/* <Widget colorTheme={colorTheme}>
        <h2>Successful Mails</h2>
        <p>{analyticsData.totalMailsSent}</p>
        </Widget>
        
        <Widget colorTheme={colorTheme}>
        <h2>Unsuccessful Mails</h2>
        <p>0</p>
      </Widget> */}

          <Widget colorTheme={colorTheme}>
            <h2>Mails Read/Opened</h2>
            <p>{analyticsData?.messagesRead}</p>
          </Widget>
          <Widget colorTheme={colorTheme}>
            <h2>Mails Replied</h2>
            <p>{analyticsData?.messagesReplied}</p>
          </Widget>

          <Widget colorTheme={colorTheme}>
            <h2>Link Clicked</h2>
            <p>{analyticsData?.messageLinkClicked}</p>
          </Widget>
          <Widget colorTheme={colorTheme}>
            <h2>Following Up</h2>
            <p>{analyticsData?.followingup}</p>
          </Widget>
        </>
      )}
    </Container>
  );
};

export default Dashboard;
