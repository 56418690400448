import { createSlice } from "@reduxjs/toolkit";

const emailAccessSlice = createSlice({
  name: "emailAccess",
  initialState: {
    userId: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload;
    },
    removeUser: (state) => {
      state.userId = null;
    },
  },
});

export const { setUser, removeUser } = emailAccessSlice.actions;

export default emailAccessSlice.reducer;
