import React from "react";
import "./privacypolicy.css";
const TermsConditions = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div class="page">
        <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
          <h1 style={{ color: "#333", fontSize: "24px" }}>
            Terms of Service Update
          </h1>
          <p style={{ color: "#666", fontSize: "14px" }}>
            Effective Date: February 13, 2024
          </p>

          <p style={{ color: "#333", fontSize: "16px" }}>
            Welcome to NexgenAI. We urge you to read these Terms of Service
            ("Terms") thoroughly before utilizing our services ("Service")
            provided by Bsdr Vision Technologies ("Company", "We", "Us", or
            "Our"). These Terms act as a binding contract between You and the
            Company, outlining the legal terms under which our Service is
            offered.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Understanding the Terminology
          </h2>
          <ul style={{ color: "#333", fontSize: "16px", marginLeft: "20px" }}>
            <li>
              <strong>Affiliate:</strong> denotes any entity with significant
              control, controlled by, or under common control with a party,
              where "control" refers to owning over 50% of voting rights or
              equity interests.
            </li>
            <li>
              <strong>Country:</strong> is Andhra Pradesh, India, the principal
              location of our Company.
            </li>
            <li>
              <strong>Device:</strong> includes any technology capable of
              accessing the Service like computers, smartphones, or tablets.
            </li>
            <li>
              <strong>Service:</strong> is the Website operated by the Company.
            </li>
            <li>
              <strong>Third-party Social Media Service:</strong> covers any
              platform that allows login or account creation to use our Service.
            </li>
            <li>
              <strong>Website:</strong> refers to NexgenAI, accessible via{" "}
              <a
                href="https://nexgenai.team"
                style={{ color: "#007bff", textDecoration: "none" }}
              >
                https://nexgenai.team
              </a>
            </li>
            <li>
              <strong>You:</strong> represents the individual or legal entity
              using our Service.
            </li>
          </ul>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Agreement to Terms
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            By accessing or using our Service, you confirm that you accept these
            Terms and agree to abide by them. If you disagree with any part of
            the Terms, you must not use our Service. Users under the age of 18
            are not permitted to use our Service.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            External Links Disclaimer
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            Our Service may contain links to external websites not operated by
            us. We have no control over and assume no responsibility for the
            content or practices of any third-party sites or services. We advise
            you to review the Terms and Privacy Policy of any third-party sites
            you visit.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Service Termination
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            We reserve the right to terminate or suspend your access to our
            Service immediately, without prior notice, for any reason, including
            breach of these Terms. Upon termination, your right to use the
            Service will cease immediately.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Limitation of Liability
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            In no event shall the Company or its suppliers be liable for any
            indirect, incidental, special, or consequential damages arising out
            of or related to your use of the Service. The total liability of the
            Company, regardless of the form of action, shall not exceed the
            greater of 100 USD or the amount you have paid to us for the
            Service.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Disclaimer of Warranties
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            The Service is provided "AS IS" and "AS AVAILABLE" without any
            warranty of any kind. The Company disclaims all warranties, express
            or implied, including but not limited to implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Governing Law and Dispute Resolution
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            These Terms shall be governed by the laws of the Country, without
            regard to its conflict of law provisions. Any disputes arising from
            these Terms will be resolved through informal negotiation or through
            an appropriate court in the Country.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>For EU Users</h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            EU consumers are entitled to any mandatory provisions of the law of
            their resident country.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            US Legal Compliance
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            You represent that you are not located in a country under US embargo
            or designated as a terrorist-supporting country and that you are not
            listed on any US prohibited parties list.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Severability and Waiver
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            If any provision of these Terms is deemed unenforceable, the
            remaining provisions will remain in full effect. Failure to enforce
            any right or provision will not be considered a waiver of those
            rights.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Amendment of Terms
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            We reserve the right to modify or replace these Terms at any time.
            If a revision is material, we will provide at least 30 days' notice
            before new terms take effect. By continuing to use our Service after
            revisions become effective, you agree to be bound by the updated
            Terms.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Contact Information
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            For any queries about these Terms, please contact us via our email:{" "}
            <a
              href="mailto:support@nexgenai.team"
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              support@nexgenai.team
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
