import React, { useEffect, useState, useRef, useContext } from "react";
import Styled from "styled-components";
import MailPage from "./MailPage";
import { useNylas } from "@nylas/nylas-react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, removeUser } from "../../redux/emailAccess";
import Connect from "./Connect";
import { ContextProvider } from "../../App";

import Axios from "axios";
const EmailContainer = Styled.div`
  /* padding:10px 20px; */
  `;
const EmailInterface = () => {
  const email = useSelector((state) => state?.user?.currentUser?.email);

  // const [userId, setUserId] = useState("");
  const dispatch = useDispatch();
  const { setNotification } = useContext(ContextProvider);
  const userId = useSelector((state) => state.emailAccess.userId);

  const hasCalledAPI = useRef(false);
  // useEffect(() => {
  //   if (!nylas) {
  //     return;
  //   }

  //   // Handle the code that is passed in the query params from Nylas after a successful login
  //   const params = new URLSearchParams(window.location.search);
  //   if (params.has("code")) {
  //     nylas
  //       .exchangeCodeFromUrlForToken()
  //       .then((user) => {
  //         const { id } = JSON.parse(user);
  //         dispatch(setUser(id));
  //         // sessionStorage.setItem("userId", id);

  //         window.location.reload(false);
  //       })
  //       .catch((error) => {
  //         console.error("An error occurred parsing the response:", error);
  //       });
  //   }
  // }, [nylas]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getAuth = async () => {
      if (hasCalledAPI.current) {
        return;
      }
      setLoading(true);
      const params = new URLSearchParams(window.location.search);
      if (params.has("code")) {
        hasCalledAPI.current = true;
        console.log("code", params.get("code"));
        const response = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/google/oauth2callback?code=` +
            params.get("code")
        );
        dispatch(setUser(response.data.id));
        setNotification("success", "The account is Syncing");
      }
      setLoading(false);
    };
    if (loading === false) {
      getAuth();
    }
  }, []);
  useEffect(() => {
    if (!userId && !loading) {
      setNotification("failure", "Please Connect Your Account");
    }
  }, []);
  return (
    <EmailContainer>
      {!userId ? (
        <Connect email={email} />
      ) : (
        // <MailPage disconnectUser={disconnectUser} />
        <MailPage />
      )}
    </EmailContainer>
  );
};

export default EmailInterface;
