import React, { useState, useContext, useEffect } from "react";
import { useNylas } from "@nylas/nylas-react";
import CalendarApp from "./CalendarApp";
import NylasLogin from "./NylasLogin";
import Layout from "./components/Layout";
import { useSelector } from "react-redux";
import { ContextProvider } from "../../App";
import Axios from "axios";
import "./styles/style.scss";

import {
  getSevenDaysFromTodayDateTimestamp,
  getTodaysDateTimestamp,
} from "./utils/date";

function App() {
  const nylas = useNylas();
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const [primaryCalendar, setPrimaryCalendar] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);

  // const getPrimaryCalendar = async () => {
  //   try {
  //     const url =
  //       process.env.REACT_APP_API_URL + "/api/calendar/nylas/read-calendars";

  //     const res = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${user.accessToken}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     console.log(res);
  //     if (!res.ok) {
  //       throw new Error(res.statusText);
  //     }

  //     const data = await res.json();
  //     // console.log(data);
  //     let [calendar] = data.filter((calendar) => calendar.is_primary);
  //     // if no primary calendar, use the first one
  //     if (!calendar && data.length) {
  //       calendar = data[0];
  //     }

  //     setPrimaryCalendar(calendar);
  //     return calendar;
  //   } catch (err) {
  //     console.warn(`Error reading calendars:`, err);
  //   }
  // };
  const getPrimaryCalendar = async () => {
    try {
      const url =
        process.env.REACT_APP_API_URL + "/api/calendar/nylas/read-calendars";

      // Axios automatically sets the Content-Type to application/json for requests with a data payload
      const res = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      console.log(res);
      const data = res.data; // In Axios, the JSON response is automatically parsed and available as res.data

      let [calendar] = data.filter((calendar) => calendar.is_primary);
      // if no primary calendar, use the first one
      if (!calendar && data.length) {
        calendar = data[0];
      }

      setPrimaryCalendar(calendar);
      return calendar;
    } catch (err) {
      console.warn(`Error reading calendars:`, err);
    }
  };
  // const getCalendarEvents = async (calendarId) => {
  //   if (calendarId) {
  //     try {
  //       const startsAfter = getTodaysDateTimestamp(); // today
  //       const endsBefore = getSevenDaysFromTodayDateTimestamp(); // 7 days from today

  //       const queryParams = new URLSearchParams({
  //         limit: 50,
  //         startsAfter,
  //         endsBefore,
  //         calendarId,
  //       });

  //       const url = `${
  //         process.env.REACT_APP_API_URL
  //       }/api/calendar/nylas/read-events?${queryParams.toString()}`;

  //       const res = await fetch(url, {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${user.accessToken}`,
  //           "Content-Type": "application/json",
  //         },
  //         params: {
  //           calendarId,
  //         },
  //       });
  //       console.log(res);
  //       if (!res.ok) {
  //         throw new Error(res.statusText);
  //       }

  //       const data = (await res.json()).filter(
  //         (event) => event.status !== "cancelled"
  //       );

  //       console.log(data);
  //       setEvents(data);
  //       setIsLoading(false);
  //     } catch (err) {
  //       console.warn(`Error reading calendar events:`, err);
  //     }
  //   }
  // };
  const getCalendarEvents = async (calendarId) => {
    if (calendarId) {
      try {
        const startsAfter = getTodaysDateTimestamp(); // today
        const endsBefore = getSevenDaysFromTodayDateTimestamp(); // 7 days from today

        // Define the base URL
        const url = `${process.env.REACT_APP_API_URL}/api/calendar/nylas/read-events`;

        // Axios request with params
        const { data } = await Axios.get(url, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
          params: {
            limit: 50,
            startsAfter,
            endsBefore,
            calendarId,
          },
        });

        console.log(data);
        const filteredData = data.filter(
          (event) => event.status !== "cancelled"
        );

        console.log(filteredData);
        setEvents(filteredData);
        setIsLoading(false);
      } catch (err) {
        console.warn(`Error reading calendar events:`, err);
      }
    }
  };

  const getPrimaryCalendarEvents = async () => {
    setIsLoading(true);
    const primaryCalendar = await getPrimaryCalendar();
    // console.log(primaryCalendar?.id);
    await getCalendarEvents(primaryCalendar?.id);
    setIsLoading(false);
  };

  const refresh = () => {
    getPrimaryCalendarEvents();
  };
  useEffect(() => {
    getPrimaryCalendarEvents();
  }, []);
  return (
    <Layout isLoading={isLoading} refresh={refresh}>
      <div className="app-card">
        <CalendarApp
          calendarId={primaryCalendar?.id}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          events={events}
          refresh={refresh}
        />
      </div>
    </Layout>
  );
}

export default App;
