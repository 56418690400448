import React, { useEffect } from "react";
import Styled from "styled-components";
import Navbar from "../components/prelaunch/Navbar";
import Desc from "../components/prelaunch/Desc";
import Features from "../components/prelaunch/Features";
import Footer from "../components/prelaunch/Footer";
import { Mobile } from "../responsive";
import axios from "axios";
import "./prelaunch.css";
const Container = Styled.div`
background: linear-gradient(to right, #180e41, #160b34, #4e2539 );

padding: 2rem 5rem;
${Mobile({
  padding: "1rem",
})}
`;

const Home = () => {
  useEffect(() => {
    // ReactGA.pageView("home");

    const TestEndpoint = async () => {
      try {
        const res = await axios.get("https://prelaunch-adixit7386.vercel.app/");
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    TestEndpoint();
  }, []);

  return (
    <Container>
      <Navbar />
      <Desc />
      <Features />

      <Footer />
    </Container>
  );
};

export default Home;
