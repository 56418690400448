import React, { useState, useEffect, useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/userReducer";

//import the fowllowing action from respective reducers
// logoutAccountBar accountReducer
// logoutChat analyticsReducer
// removeUser emailAccess
// logoutInbox inboxReducer
// logoutKnowledge knowledgeReducer
// logoutLeads leadsReducer
// logoutChat chatsReducer
import { logoutAccountBar } from "../../redux/accountReducer";
import { logoutChat } from "../../redux/analyticsReducer";
import { removeUser } from "../../redux/emailAccess";
import { logoutInbox } from "../../redux/inboxReducer";
import { logoutKnowledge } from "../../redux/knowledgeReducer";
import { logoutLeads } from "../../redux/leadsReducer";
import { logoutChats2 } from "../../redux/chatsReducer";

const { useNavigate } = require("react-router-dom");

const AccountContainer = Styled.div`
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
`;

const UserInfo = Styled.div`
  flex:1;
  margin: 20px;
  font-size: 18px;
  box-sizing: border-box;
`;

const Button = Styled.button`
  border:none;
  color:white;
  cursor:pointer;
  background-color:#1A73E8;
  &:hover{
      background-color:#1A73AA;
  }
  padding:7px 20px;
  margin-left:20px;
  border-radius:5px;
  transition:0.2s all ease;
  font-size:16px;
  font-family: 'QuickSand', sans-serif;
`;

const HeadingContainer = Styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  padding-bottom:0px;`;

const Heading = Styled.span`
  flex:1;
  font-size:32px;
  margin:0px;
  font-weight:bold;
  color:${(props) => props.colorTheme.fontPri};
  flex:1;`;

const FieldKey = Styled.span`
  font-size:20px;
  font-weight:bold;
  cursor:pointer;
  margin:0px;
  color:${(props) => props.colorTheme.fontPri};`;

const FieldValue = Styled.span`
  font-size:19px;
  margin:0px;
  color:${(props) => props.colorTheme.fontPri};`;

const PrivacyPolicyText = Styled.span`
  font-size:16px;
  margin:0px;
  color:${(props) => props.colorTheme.fontPri};
`;
const Span = Styled.span`
font-size:15px;

color:white;`;

const SpanU = Styled.span`
margin-left:30px;
text-decoration:underline;
font-size:15px;
cursor:pointer;
color:white;`;
const AccountPage = () => {
  const { colorTheme } = useContext(ContextProvider);
  const user = useSelector((state) => state.user.currentUser);

  const [isEmailPaused, setEmailPaused] = useState(false);
  const dispatch = useDispatch();
  const [emailIntent, setEmailIntent] = useState("notready");
  const [subscriptionDays, setSubscriptionDays] = useState({
    trial: "...",
    subscription: "...",
  });
  const navigate = useNavigate();
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleEmailSending = async () => {
    if (loading === true) return;
    setLoading(true);
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/emailIntent/toggleemailintent`,
      { headers: { Authorization: `Bearer ${user.accessToken}` } }
    );
    setEmailIntent(response.data.data);
    setLoading(false);
  };

  const fetchSubscriptionDays = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/subscription/getsubscriptionstatus`,
      { headers: { Authorization: `Bearer ${user.accessToken}` } }
    );
    const intent = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/emailIntent/getemailintent`,
      { headers: { Authorization: `Bearer ${user.accessToken}` } }
    );

    setSubscriptionDays({
      trial: response.data.trialDaysRemaining,
      subscription: response.data.subscriptionDaysRemaining,
    });
    setEmailIntent(intent.data.data);
  };

  const extendSubscription = () => {
    window.location.href = "https://rzp.io/i/3rklhC9T";
  };
  const handleLogout = async () => {
    dispatch(logout());
    dispatch(logoutAccountBar());
    dispatch(logoutChat());

    dispatch(removeUser());
    dispatch(logoutInbox());
    dispatch(logoutKnowledge());
    dispatch(logoutLeads());
    dispatch(logoutChats2());
  };
  useEffect(() => {
    fetchSubscriptionDays();
  }, []);
  console.log(emailIntent);
  return (
    <AccountContainer>
      <HeadingContainer>
        <Heading colorTheme={colorTheme}>Account</Heading>
      </HeadingContainer>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>UserName: </FieldKey>
        <FieldValue colorTheme={colorTheme}>{user.name}</FieldValue>
      </UserInfo>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>Email: </FieldKey>
        <FieldValue colorTheme={colorTheme}>{user.email}</FieldValue>
      </UserInfo>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>Trial Days Remaining: </FieldKey>
        <FieldValue colorTheme={colorTheme}>
          {subscriptionDays.trial}
        </FieldValue>
      </UserInfo>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>
          SubScription Days Remaining:{" "}
        </FieldKey>
        <FieldValue colorTheme={colorTheme}>
          {subscriptionDays.subscription}
        </FieldValue>
      </UserInfo>
      {emailIntent !== "notready" &&
        (emailIntent === "Resume" ? (
          <UserInfo>
            <FieldKey colorTheme={colorTheme}>Resume Sending Emails:</FieldKey>
            <Button
              onClick={() => loading === false && toggleEmailSending()}
              disabled={loading}
            >
              {loading ? "Resuming.." : "Resume"}
            </Button>
          </UserInfo>
        ) : (
          <UserInfo>
            <FieldKey colorTheme={colorTheme}>Pause Sending Emails:</FieldKey>
            <Button
              onClick={() => loading === false && toggleEmailSending()}
              disabled={loading}
            >
              {loading ? "Pausing.." : "Pause"}
            </Button>
          </UserInfo>
        ))}
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>Extend Subscription:</FieldKey>
        <Button onClick={extendSubscription}>Extend Subscription</Button>
      </UserInfo>

      <UserInfo>
        <a
          href={"https://nexgenai.team/privacy-policy"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <FieldKey colorTheme={colorTheme}>Privacy Policy</FieldKey>
        </a>
      </UserInfo>
      <UserInfo>
        <a
          href={"https://nexgenai.team/refund-policy"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <FieldKey colorTheme={colorTheme}>Refund Policy</FieldKey>
        </a>
      </UserInfo>
      <UserInfo>
        <a
          href={"https://nexgenai.team/terms-conditions"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <FieldKey colorTheme={colorTheme}>Terms & Conditions</FieldKey>
        </a>
      </UserInfo>
      <UserInfo>
        <a
          href={"https://nexgenai.team/cookies-policy"}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <FieldKey colorTheme={colorTheme}>Cookies Policy</FieldKey>
        </a>
      </UserInfo>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>Contact Us: </FieldKey>
        <FieldValue colorTheme={colorTheme}>dheemu2023@gmail.com</FieldValue>
      </UserInfo>
      <UserInfo>
        <FieldKey colorTheme={colorTheme}>Logout:</FieldKey>
        <Button onClick={() => handleLogout()}>Logout</Button>
      </UserInfo>
      <UserInfo>
        <Span>
          (NexgenAI's) use and transfer of information received from Google APIs
          to any other app will adhere to
          <a
            href={
              "https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            }
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Google API Services User Data Policy</SpanU>
          </a>
          , including the Limited Use requirements.
        </Span>
      </UserInfo>
    </AccountContainer>
  );
};

export default AccountPage;
