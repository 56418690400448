// import React, { useEffect, useState, useContext } from "react";
// import styled from "styled-components";
// import { useSelector } from "react-redux";
// import { ContextProvider } from "../App";
// import Axios from "axios";
// import Loader from "../components/General/Loader";
// // Styled components

// const SendContainer = styled.div`
//   padding: 20px 20px;
//   padding-bottom: 0px;
//   display: flex;
//   position: sticky;
//   top: 0;
//   align-items: center;
//   justify-content: center;
//   border-bottom-right-radius: 10px;
//   border-bottom-left-radius: 10px;
//   flex-direction: column;
//   background-color: ${(props) => props.colorTheme.backSec};
// `;
// const Container = styled.div``;

// const Wrapper = styled.div``;

// const LoaderContainer = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 20px;
// `;

// const DetailsContainer = styled.div`
//   flex: 1;
//   width: 100%;
//   padding-left: 20px;
//   padding-right: 20px;
//   box-sizing: border-box;
//   margin-top: 0px;
// `;

// const Row = styled.div`
//   overflow: scroll;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
// `;

// const RowFilled = styled.div`
//   overflow: scroll;
//   &::-webkit-scrollbar {
//     display: none;
//   }
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 10px;
//   background-color: ${(props) => props.colorTheme.backPri};
// `;

// const Key = styled.div`
//   padding: 12px;
//   font-weight: bold;
//   color: ${(props) => props.colorTheme.fontPri};
//   flex: 1;
// `;

// const Value = styled.div`
//   padding: 12px;
//   color: ${(props) => props.colorTheme.fontPri};
//   flex: 1;
// `;
// const ValueNum = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 12px;
//   color: ${(props) => props.colorTheme.fontPri};
//   flex: 0.1;
// `;

// // ContactsTable component for fetching and displaying contacts
// const ContactsTable = () => {
//   const [contacts, setContacts] = useState([]);
//   const { colorTheme } = useContext(ContextProvider);
//   const [loading, setLoading] = useState(false);
//   const user = useSelector((state) => state.user.currentUser);
//   useEffect(() => {
//     fetchContacts();
//   }, []);

//   const fetchContacts = async () => {
//     // Replace with your actual API endpoint
//     setLoading(true);
//     console.log(user);
//     const response = await Axios.get(
//       `${process.env.REACT_APP_API_URL}/api/contacts/nylas/read-contacts`,
//       {
//         headers: {
//           Authorization: `Bearer ${user.accessToken}`,
//         },
//       }
//     );
//     let ContactsList = [];

//     response.data.forEach((contact, index) => {
//       ContactsList.push({
//         sNo: index + 1,
//         name:
//           contact?.given_name.length > 15
//             ? contact?.given_name.slice(0, 15) + "..."
//             : contact?.given_name,
//         title:
//           contact?.job_title.length > 15
//             ? contact?.job_title.slice(0, 15) + "..."
//             : contact?.job_title,
//         company:
//           contact?.company_name.length > 15
//             ? contact?.company_name.slice(0, 15) + "..."
//             : contact?.company_name,
//         country:
//           contact?.office_location.length > 15
//             ? contact?.office_location.slice(0, 15) + "..."
//             : contact?.office_location,
//         industry: "",
//         email:
//           contact?.emails[0]?.email.length > 15
//             ? contact?.emails[0]?.email.slice(0, 15) + "..."
//             : contact?.emails[0]?.email,
//       });
//     });
//     // console.log(response.data);
//     setContacts(ContactsList);
//     setLoading(false);
//   };

//   const saveContact = async (updatedContact) => {
//     // Replace with your actual API endpoint
//     await fetch("your-api-endpoint/contacts/" + updatedContact.id, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(updatedContact),
//     });
//     fetchContacts(); // Refresh contacts after update
//   };

//   return (
//     <Container>
//       <Wrapper>
//         <SendContainer colorTheme={colorTheme}>
//           <div style={{ marginTop: "20px", width: "100%" }}>
//             <RowFilled colorTheme={colorTheme}>
//               <ValueNum colorTheme={colorTheme}>S.No</ValueNum>
//               <Key colorTheme={colorTheme}>Name</Key>

//               <Value colorTheme={colorTheme}>Title</Value>
//               <Value colorTheme={colorTheme}>Company</Value>
//               <Value colorTheme={colorTheme}>Country</Value>

//               <Value colorTheme={colorTheme}>Industry</Value>
//               <Value colorTheme={colorTheme}>Email</Value>
//             </RowFilled>
//           </div>
//         </SendContainer>
//         <DetailsContainer>
//           {loading && (
//             <LoaderContainer>
//               <Loader />
//             </LoaderContainer>
//           )}

//           {contacts?.map((user, index) =>
//             index % 2 === 0 ? (
//               <Row colorTheme={colorTheme}>
//                 <ValueNum colorTheme={colorTheme}>{index + 1}</ValueNum>
//                 <Value colorTheme={colorTheme}>
//                   <Key
//                     colorTheme={colorTheme}
//                     style={{ textDecoration: "underline" }}
//                   >
//                     {user.name}
//                   </Key>
//                 </Value>
//                 <Value colorTheme={colorTheme}>{user.title}</Value>

//                 <Value
//                   colorTheme={colorTheme}
//                   style={{ textDecoration: "underline" }}
//                 >
//                   {user.company}
//                 </Value>

//                 <Value colorTheme={colorTheme}>{user.country}</Value>
//                 <Value colorTheme={colorTheme}>{user.industry}</Value>
//                 <Value colorTheme={colorTheme}>{user.email}</Value>
//               </Row>
//             ) : (
//               <RowFilled colorTheme={colorTheme}>
//                 <ValueNum colorTheme={colorTheme}>{index + 1}</ValueNum>
//                 <Value colorTheme={colorTheme}>
//                   <Key
//                     colorTheme={colorTheme}
//                     style={{ textDecoration: "underline" }}
//                   >
//                     {user.name}
//                   </Key>
//                 </Value>
//                 <Value colorTheme={colorTheme}>{user.title}</Value>

//                 <Value
//                   colorTheme={colorTheme}
//                   style={{ textDecoration: "underline" }}
//                 >
//                   {user.company}
//                 </Value>

//                 <Value colorTheme={colorTheme}>{user.country}</Value>
//                 <Value colorTheme={colorTheme}>{user.industry}</Value>
//                 <Value colorTheme={colorTheme}>{user.email}</Value>
//               </RowFilled>
//             )
//           )}
//         </DetailsContainer>
//       </Wrapper>
//     </Container>
//   );
// };

// export default ContactsTable;

import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ContextProvider } from "../App";
import Axios from "axios";
import Loader from "../components/General/Loader";

// Styled components
const SendContainer = styled.div`
  padding: 20px 20px;
  padding-bottom: 0px;

  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  flex-direction: column;
  background-color: ${(props) => props.colorTheme.backSec};
`;
const Container = styled.div``;

const Wrapper = styled.div``;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const DetailsContainer = styled.div`
  flex: 1;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  margin-top: 0px;
`;

const Row = styled.div`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around; /* Updated for better alignment */
  border-radius: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.filled ? props.colorTheme.backSec : props.colorTheme.backPri};
`;

const RowFilled = styled(Row)`
  background-color: ${(props) => props.colorTheme.backPri};
  display: flex;
  align-items: center;
  justify-content: center;

  /* flex-direction: column; */
  flex-wrap: wrap;
`;

const Key = styled.div`
  padding: 12px;
  font-weight: bold;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 1;
`;

const Value = styled.div`
  padding: 12px;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 1;
`;

const ValueNum = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: ${(props) => props.colorTheme.fontPri};
  flex: 0.3;
`;

const EditableInput = styled.input`
  padding: 10px;
  margin: 2px;
  border-radius: 5px;
  border: 1px solid #ccc;
  flex: 1;
  width: 33%;
  outline: none;
`;

const SaveButton = styled.button`
  padding: 10px 15px;
  margin-left: 5px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #4caf50;
  color: white;
`;

// ContactsTable component
const ContactsTable = () => {
  const [contacts, setContacts] = useState([]);
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const [loading, setLoading] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const [updateLoading, setUpdateLoading] = useState(false);
  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    setLoading(true);
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/contacts/nylas/read-contacts`,
      {
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      }
    );

    let ContactsList = [];
    response.data.forEach((contact, index) => {
      ContactsList.push({
        sNo: index + 1,
        name:
          contact?.given_name.length > 15
            ? contact?.given_name.slice(0, 15) + "..."
            : contact?.given_name,
        title:
          contact?.job_title.length > 15
            ? contact?.job_title.slice(0, 15) + "..."
            : contact?.job_title,
        company:
          contact?.company_name.length > 15
            ? contact?.company_name.slice(0, 15) + "..."
            : contact?.company_name,
        country:
          contact?.office_location.length > 15
            ? contact?.office_location.slice(0, 15) + "..."
            : contact?.office_location,
        industry: "",
        email:
          contact?.emails[0]?.email.length > 15
            ? contact?.emails[0]?.email.slice(0, 15) + "..."
            : contact?.emails[0]?.email,
      });
    });
    setContacts(ContactsList);
    setLoading(false);
  };

  const toggleEditRow = (index) => {
    setEditingRow(editingRow === index ? null : index);
  };

  const handleEditChange = (e, index, field) => {
    const updatedContacts = contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: e.target.value } : contact
    );
    setContacts(updatedContacts);
  };

  const saveContact = async (index) => {
    setUpdateLoading(true);
    const updatedContact = contacts[index];
    if (
      updatedContact.name === "" ||
      updatedContact.title === "" ||
      updatedContact.company === "" ||
      updatedContact.country === "" ||
      updatedContact.industry === "" ||
      updatedContact.email === ""
    ) {
      setNotification("failure", "Please fill all the fields");
      return;
    }
    try {
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/contacts/nylas/add-leads`,
        updatedContact,
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setNotification("success", "Lead added successfully");
      //   fetchContacts(); // Optionally refresh contacts after update
      setEditingRow(null); // Exit editing mode
    } catch (error) {
      setNotification("failure", "Failed to add contact");
    }
    setUpdateLoading(false);
  };

  return (
    <Container>
      <Wrapper>
        <SendContainer colorTheme={colorTheme}>
          {/* Headers */}
          <RowFilled colorTheme={colorTheme}>
            <ValueNum colorTheme={colorTheme}>S.No</ValueNum>
            <Key colorTheme={colorTheme}>Name</Key>
            <Value colorTheme={colorTheme}>Title</Value>
            <Value colorTheme={colorTheme}>Company</Value>
            <Value colorTheme={colorTheme}>Country</Value>
            <Value colorTheme={colorTheme}>Industry</Value>
            <Value colorTheme={colorTheme}>Email</Value>
          </RowFilled>
        </SendContainer>
        <DetailsContainer>
          {loading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            contacts.map((contact, index) =>
              editingRow === index ? (
                <Row
                  filled={index % 2 === 0 ? true : false}
                  key={contact.id}
                  colorTheme={colorTheme}
                >
                  <EditableInput
                    type="text"
                    placeholder="Name"
                    value={contact.name}
                    onChange={(e) => handleEditChange(e, index, "name")}
                  />
                  {/* Repeat for other fields */}
                  <EditableInput
                    type="text"
                    placeholder="Title"
                    value={contact.title}
                    onChange={(e) => handleEditChange(e, index, "title")}
                  />
                  <EditableInput
                    type="text"
                    placeholder="Company"
                    value={contact.company}
                    onChange={(e) => handleEditChange(e, index, "company")}
                  />
                  <EditableInput
                    type="text"
                    placeholder="Country"
                    value={contact.country}
                    onChange={(e) => handleEditChange(e, index, "country")}
                  />
                  <EditableInput
                    type="text"
                    placeholder="Industry"
                    value={contact.industry}
                    onChange={(e) => handleEditChange(e, index, "industry")}
                  />
                  <EditableInput
                    type="email"
                    placeholder="Email"
                    value={contact.email}
                    onChange={(e) => handleEditChange(e, index, "email")}
                  />

                  <SaveButton onClick={() => saveContact(index)}>
                    {updateLoading ? "Saving" : "Save"}
                  </SaveButton>
                </Row>
              ) : (
                <Row
                  key={contact.id}
                  onClick={() => toggleEditRow(index)}
                  colorTheme={colorTheme}
                  filled={index % 2 === 0 ? true : false}
                >
                  <ValueNum colorTheme={colorTheme}>{contact.sNo}</ValueNum>
                  <Value colorTheme={colorTheme}>{contact.name}</Value>
                  <Value colorTheme={colorTheme}>{contact.title}</Value>
                  <Value colorTheme={colorTheme}>{contact.company}</Value>
                  <Value colorTheme={colorTheme}>{contact.country}</Value>
                  <Value colorTheme={colorTheme}>{contact.industry}</Value>
                  <Value colorTheme={colorTheme}>{contact.email}</Value>

                  {/* Display static values for other fields */}
                </Row>
              )
            )
          )}
        </DetailsContainer>
      </Wrapper>
    </Container>
  );
};

export default ContactsTable;
