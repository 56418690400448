import React from "react";
import Styled from "styled-components";
import Heading from "./Heading";
import { Mobile } from "../../responsive";
const Container = Styled.div`

margin:2rem;
background-color:grey;
background-color:rgba(50,50,50,0);
padding:10px 20px;
border-radius:10px;
`;
const Top = Styled.div`

display:flex;
align-items:center;
justify-content:center;`;

const Middle = Styled.div`
padding-top:10px;
padding-bottom:10px;
display:flex;
align-items:center;
justify-content:space-between;
border-top: 1px solid ;
border-image: linear-gradient(60deg,#a55eea, #5f27cd) 1;`;

const Left = Styled.div`
flex:1;`;
const Right = Styled.div`
flex:1;
display:flex;
align-items:center;
justify-content:flex-end;
${Mobile({
  flexDirection: "column",
})};
`;

const Span = Styled.span`
font-size:15px;

color:white;`;

const SpanU = Styled.span`
margin-left:30px;
text-decoration:underline;
font-size:15px;
cursor:pointer;
color:white;
${Mobile({
  marginLeft: "0px",
  marginTop: "10px",
})};`;

const Footer = () => {
  return (
    <Container>
      <Top>
        <Heading />
      </Top>

      <Middle>
        <Left>
          <Span>2024 NexgenAI © All rights reserved.</Span>
        </Left>

        <Right>
          <a
            href={"https://nexgenai.team/privacy-policy"}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Privacy Policy</SpanU>
          </a>
          <a
            href={"https://nexgenai.team/terms-conditions"}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Terms & Conditions</SpanU>
          </a>
          <a
            href={"https://nexgenai.team/refund-policy"}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Refund Policy</SpanU>
          </a>
          <a
            href={"https://nexgenai.team/cookies-policy"}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <SpanU>Cookies Policy</SpanU>
          </a>
        </Right>
      </Middle>
    </Container>
  );
};

export default Footer;
