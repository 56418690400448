import React, { useState, useContext } from "react";
import { ContextProvider } from "../App";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Mobile, Tablet } from "../responsive";
import jwt_decode from "jwt-decode";
import Text from "../components/General/Text";
import Styled from "styled-components";
import Axios from "axios";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import Loader from "../components/General/Loader";
const Container = Styled.div`
font-family:QuickSand,Sans-Serif;
margin-top:10vh;
margin-bottom:10vh;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;

`;

const Wrapper = Styled.div`
width:30%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:10px;
background-color:${(props) => props.bgColor};
${Mobile({
  width: "80%",
})};
${Tablet({
  width: "70%",
})};
`;

const HeadingContainer = Styled.div`
padding:20px 30px;
display:flex;
align-items:center;
justify-content:center;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;

const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 20px;
`;

const AutoLoginContainer = Styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;
padding:5px 20px;
`;

const PartitionContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:5px 20px;
`;

const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;
const AdditionalContainer = Styled.div`
/* display:flex;
align-items:center;*/
justify-content:center;
padding:10px 10px;
text-align:center;
`;
const Input = Styled.input``;
const Text2 = Styled.span`
text-decoration:underline;

cursor:pointer;
margin:0px;
color:${(props) => props.ftColor};
font-size:${(props) => (props.size ? props.size : "1.1rem")};
font-weight:${(props) => (props.weight ? props.weight : "normal")};
`;
const Text3 = Styled.span`


cursor:pointer;
margin:0px;
color:${(props) => props.ftColor};
font-size:${(props) => (props.size ? props.size : "1.1rem")};
font-weight:${(props) => (props.weight ? props.weight : "normal")};
`;
//validation for all the fields
const validate = (name, email, website, password, confirmPassword) => {
  // Check if all fields are present
  if (!name) {
    return { error: true, message: "Please provide a name." };
  }
  if (!email) {
    return { error: true, message: "Please provide an email." };
  }
  if (!website) {
    return { error: true, message: "Please provide a website." };
  }
  if (!password) {
    return { error: true, message: "Please provide a password." };
  }
  if (!confirmPassword) {
    return { error: true, message: "Please confirm your password." };
  }

  // Validate email format
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailRegex.test(email)) {
    return { error: true, message: "Invalid email format." };
  }

  // Validate password complexity
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(password)) {
    return {
      error: true,
      message: "Weak Password",
    };
  }

  // Check if passwords match
  if (password !== confirmPassword) {
    return { error: true, message: "Passwords do not match." };
  }

  // If all validations pass
  return { error: false, message: "Validation successful." };
};
const TitleContainer = Styled.div`
padding:10px 10px 0px 10px;
display:flex;
align-items:center;
justify-content:center;
color:${(props) => props.colorTheme.fontPri};
`;
const Title = Styled.h1`
margin: 0px auto 0px auto;
cursor:pointer;
font-size:32px;;`;
const Span = Styled.span`
font-size:32px;
color:#0081B4;`;
const Login = () => {
  const { colorTheme, theme, setNotification } = useContext(ContextProvider);

  const { backPri, backSec, fontPri, fontSec } = colorTheme;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      setNotification("failure", "Please agree to the terms and conditions");
      return;
    }
    const errObj = validate(name, email, website, password, confirmPassword);
    if (errObj.error) {
      setNotification("failure", errObj.message);
      return;
    }
    setLoading(true);
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/user/register`,
        {
          name: name,
          email: email,
          companyWebsite: website,
          password: password,
        }
      );
      console.log(response);
      setNotification("success", "User Registered Successfully");
      navigate("/login");
      return;
    } catch (error) {
      setNotification("failure", "Server Error");
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
      <Wrapper bgColor={backSec}>
        <TitleContainer colorTheme={colorTheme}>
          <Title>
            Nexgen
            <Span>AI</Span>
          </Title>
        </TitleContainer>
        <HeadingContainer>
          <Text
            ftColor={fontPri}
            val={"Register"}
            ftSize={"32px"}
            ftWeight={"Bolder"}
          ></Text>
        </HeadingContainer>
        <ContentContainer>
          <FormContainer>
            <Form>
              <InputContainer
                label="name"
                placeholder="abc def"
                type="name"
                input={name}
                setInput={setName}
              />
              <InputContainer
                label="email"
                placeholder="abc@gmail.com"
                type="email"
                input={email}
                setInput={setEmail}
              />
              <InputContainer
                label="Company Website"
                placeholder="abc.com"
                type="text"
                input={website}
                setInput={setWebsite}
              />
              <InputContainer
                label="password"
                placeholder="password"
                type="password"
                input={password}
                setInput={setPassword}
              ></InputContainer>
              <InputContainer
                label="Repeat Password"
                placeholder="password"
                type="password"
                input={confirmPassword}
                setInput={setConfirmPassword}
              ></InputContainer>
            </Form>
          </FormContainer>
          <ButtonContainer>
            <Button
              text={loading ? <Loader /> : "Register"}
              compose={handleLogin}
            ></Button>
          </ButtonContainer>

          <AdditionalContainer>
            <Input
              type="checkbox"
              checked={isChecked}
              onChange={handleOnChange}
            ></Input>
            <Text3 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
              By Signing Up, I agree to the{" "}
            </Text3>
            <a
              href={"https://nexgenai.team/terms-conditions"}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text2 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
                Terms and Conditions{" "}
              </Text2>
            </a>
            <a
              href={"https://nexgenai.team/privacy-policy"}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text2 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
                Privacy Policy{" "}
              </Text2>
            </a>
            <a
              href={"https://nexgenai.team/cookies-policy"}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text2 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
                Cookie Policy{" "}
              </Text2>
            </a>
            <a
              href={"https://nexgenai.team/refund-policy"}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text2 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
                Refund Policy
              </Text2>
            </a>
          </AdditionalContainer>
          <AdditionalContainer>
            <Text3 ftColor={fontSec} ftSize={"20px"} ftWeight={"bolder"}>
              Already have an account?
            </Text3>
            <Text2
              ftColor={fontSec}
              ftSize={"20px"}
              ftWeight={"bolder"}
              onClick={() => navigate("/login")}
            >
              Login
            </Text2>
          </AdditionalContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Login;
