import React from "react";
import Styled from "styled-components";
import Button from "./Button";
import { Mobile } from "../../responsive";
import { useNavigate } from "react-router-dom";
const Container = Styled.div`
box-sizing:border-box;
flex:1;
margin :2rem;
border:solid 4px #5f27cd;
padding:4px;
border-radius: 10px; 
${Mobile({
  margin: "1rem",
})}`;

const ImageContainer = Styled.div`
flex:1;
width:100%;
height:250px;
width:250px;
display:flex;
justify-content:center;
align-items:center;
`;
const Title = Styled.div`
margin-top:1rem;
width:100%;
display:flex;
align-items:center;
justify-content:center;
text-align:center;
flex:1;`;

const Heading = Styled.span`
display:flex;
align-items:center;
justify-content:center;
font-size:1.5rem;
font-weight:600;
color:white;
width:100%;
`;

const Subtitle = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex:1;`;

const SubHeading = Styled.h2`
font-size:1.2rem;
font-weight:500;
color:white;
margin:1rem;
text-align:center;
line-height:1.7;
`;

const Description = Styled.div`
flex:1;
color:white;
font-size:1.1rem;`;

const Wrapper = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;`;

const Image = Styled.img`
height:100%;
width:100%;
object-fit:cover;
border-radius:50%;
`;
const UL = Styled.ul``;
const LI = Styled.li`
margin:0.5rem 0rem 0.5rem 0rem;
line-height:1.7;`;

const ButtonContainer = Styled.div`
flex:1;
margin:7px 12px;`;
const FeatureBox = ({ img, title, subtitle, description, status }) => {
  console.log(description);
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <ImageContainer>
          <Image src={img} alt="this is the " />
        </ImageContainer>
        <Title>
          <Heading>{title}</Heading>
        </Title>
        <Subtitle>
          <SubHeading>{subtitle}</SubHeading>
        </Subtitle>
        <Description>
          <UL>
            {description.map((item) => (
              <LI>{item}</LI>
            ))}
          </UL>
        </Description>
        <ButtonContainer onClick={() => navigate("/login")}>
          <Button text={status} />
        </ButtonContainer>
      </Wrapper>
    </Container>
  );
};

export default FeatureBox;
