import React, { useState, useContext } from "react";
import IconSync from "./icons/IconSync.jsx";
import IconLogout from "./icons/IconLogout.jsx";
import NylasLogo from "./icons/nylas-logo-horizontal.svg";
import PropTypes from "prop-types";
import { ContextProvider } from "../../../App.js";
const Layout = ({
  children,
  showMenu = false,

  refresh,
  isLoading,
}) => {
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const handleRefresh = (e) => {
    e.preventDefault();
    refresh();
  };

  return (
    <div style={{ backgroundColor: colorTheme.backSec }} className="layout">
      <div className="title-menu">
        {showMenu && (
          <div className="menu">
            <button
              onClick={handleRefresh}
              disabled={isLoading || isDisconnecting}
            >
              <div className={`menu-icon ${isLoading ? "syncing" : ""}`}>
                <IconSync />
              </div>
              <span className="hidden-mobile">
                {isLoading ? "Refreshing" : "Refresh"}
              </span>
            </button>
            <div className="hidden-mobile">·</div>
          </div>
        )}
      </div>
      <main>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  showMenu: PropTypes.bool.isRequired,
  disconnectUser: PropTypes.func,
  refresh: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

export default Layout;
