import React from "react";
import "./privacypolicy.css";
const PrivacyPolicy = () => {
  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="page">
        <div style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
          <h1 style={{ color: "#333", fontSize: "24px" }}>
            Privacy Policy Update
          </h1>
          <p style={{ color: "#666", fontSize: "14px" }}>
            Effective Date: February 13, 2024
          </p>

          <p style={{ color: "#333", fontSize: "16px" }}>
            This updated Privacy Policy outlines our commitment to safeguarding
            the privacy of our users while using our services ("Service"). It
            addresses the collection, use, and disclosure of personal
            information, emphasizing our compliance with the Limited Use
            Requirements under the Google API Services User Data Policy.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Our Commitment to Your Privacy
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            Your privacy is paramount to us. We collect and use your personal
            data solely to enhance your experience with our Service. By
            accessing our Service, you consent to our data practices as
            described in this policy. This document was crafted with the
            assistance of a Privacy Policy Generator to ensure clarity and
            compliance.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Understanding Terms
          </h2>
          <ul style={{ color: "#333", fontSize: "16px", marginLeft: "20px" }}>
            <li>
              <strong>Account:</strong> refers to a unique user-created profile
              for accessing our Service.
            </li>
            <li>
              <strong>Affiliate:</strong> indicates an entity that has a
              significant control relationship with us, defined by majority
              ownership or voting rights.
            </li>
            <li>
              <strong>Company:</strong> (also "We", "Us", or "Our") refers to
              Bsdr Vision Technologies, located at D.no 13/349-j-25,ushakiran
              High School Pyramid Nagar Vempalli, Cuddapah Cuddapah Ap 516329.
            </li>
            <li>
              <strong>Cookies:</strong> are data files placed on your device to
              collect internet usage information.
            </li>
            <li>
              <strong>Country:</strong> is Andhra Pradesh, India, where our
              Company is based.
            </li>
            <li>
              <strong>Device:</strong> means any technology, such as a computer
              or smartphone, that can access our Service.
            </li>
            <li>
              <strong>Personal Data:</strong> is information that identifies you
              as an individual.
            </li>
            <li>
              <strong>Service Provider:</strong> denotes any entity that
              processes data on our behalf.
            </li>
            <li>
              <strong>Third-party Social Media Service:</strong> includes
              platforms like Google, Facebook, and Twitter that facilitate
              account creation and login.
            </li>
            <li>
              <strong>Usage Data:</strong> is automatically collected data about
              how the Service is accessed and used.
            </li>
            <li>
              <strong>Website:</strong> directs you to our Service, accessible
              at{" "}
              <a
                href="https://nexgenai.team"
                style={{ color: "#007bff", textDecoration: "none" }}
              >
                https://nexgenai.team
              </a>
            </li>
            <li>
              <strong>You:</strong> refers to the individual using our Service
              or the entity on whose behalf the Service is used.
            </li>
          </ul>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Data Collection and Use
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            We collect various information to provide and improve our Service.
            This includes:
          </p>
          <ul style={{ color: "#333", fontSize: "16px", marginLeft: "20px" }}>
            <li>Personal Identifiers like email, name, and contact details.</li>
            <li>Usage Data detailing your interaction with our Service.</li>
          </ul>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Limited Use Compliance
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            In adherence to the Limited Use Requirements, we commit to:
          </p>
          <ul style={{ color: "#333", fontSize: "16px", marginLeft: "20px" }}>
            <li>
              Limiting data use to providing or enhancing features clearly
              evident in our user interface.
            </li>
            <li>
              Prohibiting data transfers except for feature provision, security,
              legal compliance, or during business restructuring, always with
              your explicit consent.
            </li>
            <li>
              Ensuring no human access to data without your affirmative consent,
              except for security or legal reasons, or in aggregated form for
              internal operations.
            </li>
            <li>
              Strictly forbidding data transfers to third parties for
              advertising, credit evaluation, or any unrelated purposes.
            </li>
          </ul>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Data Retention and Security
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            We retain your Personal Data only as long as necessary for the
            purposes outlined in this Privacy Policy, adhering to legal and
            security standards. Despite employing robust measures to protect
            your data, no digital platform can guarantee absolute security.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Children's Privacy
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            Our Service is not intended for individuals under 13. We do not
            knowingly collect data from children and will take steps to delete
            such information if discovered.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>External Links</h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            Our Service may contain links to third-party sites. We are not
            responsible for the privacy practices of these external sites.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Policy Modifications
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            We may update this Privacy Policy to reflect changes in our
            practices. Significant changes will be communicated through our
            Service or via email.
          </p>

          <h2 style={{ color: "#333", fontSize: "20px" }}>
            Contact Information
          </h2>
          <p style={{ color: "#333", fontSize: "16px" }}>
            For questions or concerns about our privacy practices, please
            contact us via email at{" "}
            <a
              href="mailto:support@nexgenai.team"
              style={{ color: "#007bff", textDecoration: "none" }}
            >
              support@nexgenai.team
            </a>
          </p>

          <p style={{ color: "#333", fontSize: "16px" }}>
            Your engagement with our Service following these updates constitutes
            acceptance of the revised Privacy Policy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
