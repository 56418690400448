import React, { useContext } from "react";
import Styled from "styled-components";

const Container = Styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:center;`;
const Loaders = Styled.div`
/* margin-top:5px;
margin-bottom:5px; */
border: 4px solid #f3f3f3!important; /* Light grey */
  border-top: 4px solid #3498db!important; /* Blue */
  border-radius: 50%!important;
  width: 12px!important;
  height: 12px!important;
  
  animation: spin 1s linear infinite;
  
  @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`;

const Loader = () => {
  return (
    <Container>
      <Loaders></Loaders>
    </Container>
  );
};

export default Loader;
