import React, { useState } from "react";
import Styled from "styled-components";
import Button from "./Button";
import { Mobile, Min } from "../../responsive";
import axios from "axios";
import Loader from "./Loader";
// import ReactGA from "react-ga4";

const Container = Styled.div`
margin-top:1rem;
height: 3rem;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`;
const InputContainer = Styled.div`
display: flex;
align-items: center;
justify-content: center;

width:50%;
height:90px;
padding:10px!important;
padding-right:5px;
border: 2px solid #5f27cd;
border-radius: 50px;
${Mobile({
  width: "100%",
})}
${Min({
  height: "80px",
})}
`;

const InputBox = Styled.input`
flex:7;
height: 100%;
width: 100%;
border: none;
outline: none;
font-size: 1.2rem;
outline:none;
border-radius: 5px;
padding: 0px 10px!important;
color:white;
background-color:transparent;
font-family: 'Lato', sans-serif;

${Mobile({
  width: "60%",
})}
`;
const ButtonContainer = Styled.div`
flex:5;
height: 100%;
display: flex;
align-items: center;
justify-content: flex-end;
${Mobile({
  flex: "7",
  width: "70%",
})}
`;
const DiscordContainer = Styled.div``;
const Img = Styled.img`
height:60px;
width:60px;
cursor:pointer;
margin-left:10px;
`;
const Input = ({ setError }) => {
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  function ValidateEmail(input) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  }
  const load = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setEmail("");
      setSuccess(true);
    }, 500);
  };

  const submitMail = async () => {
    // ReactGA.event({
    //   category: "User",
    //   action: "User Subscribed",
    //   value: email,
    // });
    var data = email;
    load();
    if (!ValidateEmail(email)) {
      setError(true);
      return;
    }

    setError(false);
    try {
      await axios.post("https://prelaunch-adixit7386.vercel.app/users", {
        email: data,
      });
    } catch (error) {}
    // ReactGA.event({
    //   category: "Users",
    //   action: "subscribe",
    //   value: data,
    // });
  };

  return (
    <Container>
      <InputContainer>
        <InputBox
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          placeholder={"Enter Your Mail"}
        />
        {success ? (
          <ButtonContainer>
            <Button text={"Requested For"} text2={"Access"} />
          </ButtonContainer>
        ) : (
          <ButtonContainer onClick={() => submitMail()}>
            {loading ? (
              <Loader />
            ) : (
              <Button
                onClick={() => submitMail()}
                text={"Request Beta "}
                text2={"Access"}
              />
            )}
          </ButtonContainer>
        )}
      </InputContainer>
      <DiscordContainer>
        <a href="https://discord.gg/8uEESCXQ" target={"blank"}>
          <Img src={"static/discord.svg"} alt={"discord"} />
        </a>
      </DiscordContainer>
    </Container>
  );
};
export default Input;
