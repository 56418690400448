import React from "react";
import Styled from "styled-components";
import { Mobile } from "../../responsive";
const HeadingComp = Styled.h1`
font-size: 2.5em;
font-weight: 800;
background: linear-gradient(to right, #5148b4, #88b8d4);
-webkit-background-clip: text;
color: transparent;
`;
const Title = Styled.h1`
margin: 0px auto 0px auto;
cursor:pointer;
color:white;
font-size:38px;
${Mobile({ fontSize: "32px" })};`;
const Span = Styled.span`
font-size:38px;
${Mobile({ fontSize: "32px" })};
color:#0081B4;`;
const Heading = () => {
  return (
    <Title>
      Nexgen
      <Span>AI</Span>
    </Title>
  );
};

export default Heading;
