import { createSlice } from "@reduxjs/toolkit";

const inboxSlice = createSlice({
  name: "inbox",
  initialState: {
    currentData: null,
  },
  reducers: {
    setData: (state, action) => {
      state.currentData = action.payload;
    },
    logoutInbox: (state) => {
      state.currentData = null;
    },
  },
});

export const { setData, logoutInbox } = inboxSlice.actions;

export default inboxSlice.reducer;
