import React, { useState, useEffect, useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import Button from "../General/Button";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../redux/knowledgeReducer";
import Loader from "../General/Loader";
const LoaderContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  top:0px;
  /* position:absolute; */
`;
const Container = Styled.div` 
`;
const HeadingContainer = Styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding:30px;
  padding-bottom:0px;`;

const Heading = Styled.span`
flex:1;
  font-size:32px;

  margin:0px;
  font-weight:bold;
  color:${(props) => props.colorTheme.fontPri};
  flex:1;`;

const Wrapper = Styled.div`
`;

const DetailsContainer = Styled.div`
  margin: 20px;
`;

const TableData = Styled.div``;

const Row = Styled.div`
display:flex;
align-items:center;
justify-content:center;

border-radius:10px;`;

const RowFilled = Styled.div`
display:flex;
align-items:center;
justify-content:center;
border-radius:10px;
background-color:${(props) => props.colorTheme.backPri};
`;

const Key = Styled.div`
padding: 12px;
font-weight:bold;
color:${(props) => props.colorTheme.fontPri};
flex:1;`;

const Value = Styled.div`
padding: 12px;
color:${(props) => props.colorTheme.fontPri};
flex:1;`;

const InputField = Styled.input`
  width: 100%;
  padding: 7px 12px;
  box-sizing: border-box;
  font-size:14px;
  border:none;
  outline:none;
  font-family: 'Quicksand', sans-serif;
  border-radius:10px;
  background-color:${(props) => props.colorTheme.backSec};
  color:${(props) => props.colorTheme.fontPri};
  `;
const InputFieldFilled = Styled.input`
  width: 100%;
  border-radius:10px;
  padding: 7px 12px;
  box-sizing: border-box;
  font-size:14px;
  border:none;
  outline:none;
  font-family: 'Quicksand', sans-serif;
  background-color:${(props) => props.colorTheme.backPri};
  color:${(props) => props.colorTheme.fontPri};
`;

const ButtonContainer = Styled.div`
padding:20px 0px;`;

const Home = () => {
  const [isEditing, setIsEditing] = useState(false);
  const { colorTheme } = useContext(ContextProvider);
  const user = useSelector((state) => state.user.currentUser);
  const userData = useSelector((state) => state.knowledge.currentData);

  const handleUpdate = () => {
    setIsEditing(false);
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchKnowledgeBase = async () => {
      setLoading(true);
      try {
        let response = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/knowledgebase/getknowledgebase`,
          { headers: { Authorization: `Bearer ${user.accessToken}` } }
        );
        response.data.companySize = `Min: ${response.data.companySize.min} Max: ${response.data.companySize.max}`;
        response.data.revenue = `Min: ${response.data.revenue.min} Max: ${response.data.revenue.max}`;

        // setUserData(response.data);
        console.log(response.data);
        dispatch(setData(response.data));
      } catch (error) {}
      setLoading(false);
    };
    fetchKnowledgeBase();
  }, []);
  console.log(userData);

  return (
    <Container>
      <Wrapper>
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <HeadingContainer>
          <Heading colorTheme={colorTheme}>Knowledge Base</Heading>
        </HeadingContainer>
        <DetailsContainer>
          {!userData ? (
            <></>
          ) : (
            <TableData>
              {Object.entries(userData).map(([key, value], index) =>
                index % 2 === 0 ? (
                  <Row key={key} colorTheme={colorTheme}>
                    <Key colorTheme={colorTheme}>
                      {key.replace(/([A-Z])/g, " $1").trim()}
                    </Key>

                    <Value colorTheme={colorTheme}>{value}</Value>
                  </Row>
                ) : (
                  <RowFilled colorTheme={colorTheme} key={key} theme>
                    <Key colorTheme={colorTheme}>
                      {key.replace(/([A-Z])/g, " $1").trim()}
                    </Key>

                    <Value colorTheme={colorTheme}>{value}</Value>
                  </RowFilled>
                )
              )}
            </TableData>
          )}
        </DetailsContainer>
      </Wrapper>
    </Container>
  );
};

export default Home;
