import React, { useEffect, useContext } from "react";
import Styled from "styled-components";
import SendIcon from "@mui/icons-material/Send";
import { Mobile } from "../../responsive";
import { ContextProvider } from "../../App";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setData } from "../../redux/chatsReducer";
import { Bars } from "react-loader-spinner";
const Container = Styled.div`
flex:5;
height:calc(100vh - 60px);

`;

const SendContainer = Styled.div`
position:sticky;
height:80px;
top:100%;
display:flex;
align-items:center;
justify-content:center;
border-bottom-right-radius:10px;
border-bottom-left-radius:10px;
`;

const Center = Styled.div`
width:80%;
display:flex;
align-items:center;
justify-content:center;`;

const InputContainer = Styled.div`
flex:9;
height:45px;
display:flex;
align-items:center;
jusify-content:center;
border:none;
border-top-left-radius:50px;
border-bottom-left-radius:50px;
padding:2px 20px;

background-color:${(props) => props.colorTheme.backPri};
`;
const Input = Styled.input`
color:${(props) => props.colorTheme.fontPri};
font-size: 18px;
font-size: 18px;
flex: 1;
width:80%;
border:none;
height: 30px;
font-family: 'Quicksand', sans-serif;
outline:none;
&:focus{
 font-size: 18px;
font-size: 18px;
flex: 1;
border:none;
height: 35px;
outline:none;
};
background-color:${(props) => props.colorTheme.backPri};

`;

const SearchIconContainer = Styled.div`

height:45px;
width:45px;
border-left:none;
display:flex;
align-items:center;
jusify-content:center;
border-top-right-radius:50px;
border-bottom-right-radius:50px;
padding:2px 4px;
border:none;
background-color:${(props) => props.colorTheme.backPri};
`;
const MessageContainerSender = Styled.div`

width:100%;
height:content-fit;
display:flex;
align-items:center;
margin:10px 0px;
padding-left:60px;
box-sizing:border-box;
justify-content:flex-start;


`;
const MessageContainer = Styled.div`
width:100%;
height:content-fit;
display:flex;
align-items:center;
justify-content:flex-end;

`;
const SenderMessage = Styled.div`
width:content-fit;
max-width:40%;
margin-left:20px;
background-color:white;
border-radius:20px;
border-top-left-radius:0px;
padding:15px 10px;
background-color:${(props) => props.colorTheme.backPri};
`;

const UserMessage = Styled.div`
width:content-fit;
max-width:40%;
margin-right:20px;
border-radius:20px;
border-top-right-radius:0px;
background-color:${(props) => props.colorTheme.mainColor};
padding:15px 10px;
`;

const MessageDetail = Styled.div`
height:content-fit;
display:flex;
align-items:center;
justify-content:space-between;
`;

const MessageContent = Styled.div`
height:content-fit;
`;

const NameText = Styled.span`
font-size:17px;
font-weight:bold;
color:white;`;

const NameTextSender = Styled.span`
font-size:17px;
font-weight:bold;
color:${(props) => props.colorTheme.mainColor};`;

const MessageText = Styled.span`
color:${(props) => props.colorTheme.fontPri};
padding:2px 4px;
font-size:18px;`;

const MessageTextUser = Styled.span`
color:white;
padding:2px 4px;
font-size:18px;`;

const UserIconContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
width:55px;
height:55px;
`;

const MessageListContainer = Styled.div`
height:calc(100vh - 140px);
&::-webkit-scrollbar{
    display:none;
};
display:flex;
flex-direction:column-reverse;
overflow-x:scroll;`;
const Img = Styled.img`
height:60px;
width:60px;
border-radius:50%;`;

const Chats = () => {
  const [message, setMessage] = React.useState("");
  const [messageLoading, setMessageLoading] = React.useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const messageList = useSelector((state) => state.chats.currentData);
  const dispatch = useDispatch();
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const sendMessage = async () => {
    if (message.length > 250) {
      setNotification("failure", "Message exceeds 250 characters!!");
      return;
    }
    setMessageLoading(true);
    const newMessage = { message: message, role: "user" };
    const loadMessage = { message: "", role: "loading" };
    dispatch(setData([loadMessage, newMessage, ...messageList]));
    setMessage("");

    let isResponseReceived = false;

    const handleTimeout = () => {
      if (!isResponseReceived) {
        const ms = messageList.filter(
          (msg) => msg !== newMessage || msg !== loadMessage
        );
        dispatch(setData(ms));
        setNotification("failure", "error!! Please try again");
      }
    };

    const timer = setTimeout(handleTimeout, 80000);

    try {
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat/chat`,

        {
          message: message,
        },
        {
          headers: { Authorization: `Bearer ${user.accessToken}` },
        }
      );
      isResponseReceived = true;
      fetchChat();
    } catch (error) {
      // console.log(error);
      if (!isResponseReceived) {
        const ms = messageList.filter(
          (msg) => msg !== newMessage || msg !== loadMessage
        );
        dispatch(setData(ms));
        setNotification("failure", "error!! Please try again");
      }
    } finally {
      clearTimeout(timer);
    }
    setMessageLoading(false);
  };

  const fetchChat = async () => {
    const res = await Axios.get(
      `${process.env.REACT_APP_API_URL}/api/fetchchat/chat`,
      {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      }
    );
    const ms = res.data.chats.reverse();
    dispatch(setData(ms));
  };
  useEffect(() => {
    fetchChat();
  }, []);
  return (
    <Container>
      <MessageListContainer>
        {!messageList ? (
          <></>
        ) : (
          messageList.map((item) => (
            <>
              {item.role === "user" ? (
                <MessageContainer colorTheme={colorTheme}>
                  <UserIconContainer></UserIconContainer>
                  <UserMessage colorTheme={colorTheme}>
                    <MessageDetail colorTheme={colorTheme}>
                      <NameText colorTheme={colorTheme}>You</NameText>
                    </MessageDetail>
                    <MessageContent colorTheme={colorTheme}>
                      <MessageTextUser colorTheme={colorTheme}>
                        {item.message}
                      </MessageTextUser>
                    </MessageContent>
                  </UserMessage>
                  <UserIconContainer></UserIconContainer>
                </MessageContainer>
              ) : item.role === "loading" ? (
                <MessageContainerSender colorTheme={colorTheme}>
                  <UserIconContainer>
                    <Img src="static/sophia.jpg" />
                  </UserIconContainer>

                  <div style={{ marginLeft: "20px" }}>
                    <Bars
                      height="60"
                      width="60"
                      color={colorTheme.backPri}
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                </MessageContainerSender>
              ) : (
                <MessageContainerSender colorTheme={colorTheme}>
                  <UserIconContainer>
                    <Img src="static/sophia.jpg" />
                  </UserIconContainer>
                  <SenderMessage colorTheme={colorTheme}>
                    <MessageDetail colorTheme={colorTheme}>
                      <NameTextSender colorTheme={colorTheme}>
                        Sophia
                      </NameTextSender>
                    </MessageDetail>
                    <MessageContent colorTheme={colorTheme}>
                      <MessageText colorTheme={colorTheme}>
                        {item.message}
                      </MessageText>
                    </MessageContent>
                  </SenderMessage>
                </MessageContainerSender>
              )}
            </>
          ))
        )}
      </MessageListContainer>

      <SendContainer colorTheme={colorTheme}>
        <Center colorTheme={colorTheme}>
          <InputContainer
            colorTheme={colorTheme}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                !messageLoading && sendMessage();
              }
            }}
          >
            <Input
              colorTheme={colorTheme}
              placeholder="Type to Chat"
              className="NavbarInput"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </InputContainer>
          <SearchIconContainer colorTheme={colorTheme}>
            <SendIcon
              style={{
                height: "35px",
                width: "35px",
                cursor: "pointer",
                color: colorTheme.mainColor,
                "@media max-width:(480px)": {
                  height: "30px",
                  width: "30px",
                },
              }}
              onClick={() => {
                !messageLoading && sendMessage();
              }}
            />
          </SearchIconContainer>
        </Center>
      </SendContainer>
    </Container>
  );
};

export default Chats;
